import React from 'react'
import TextField from '@material-ui/core/TextField'
import regions from '../../../../data/regions.json'
import InputMask from 'react-input-mask'
import Menuitem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import styles from '../../MyAccount.module.scss'

type EditAddressType = {
  editAddress: any
  handleEditAddress: any
  saveEditAddress: any
  handleOpenEditAddress: any
  savingIn: any
}

const editAddress: React.FC<EditAddressType> = ({ editAddress, handleEditAddress, saveEditAddress, handleOpenEditAddress, savingIn }) => {

  return (
    <div className={styles.gridAddresses}>
      <span className={styles.subtitles} style={{ width: '100%' }}>Editar endereço</span>
      <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: '10px' }}>
        <div style={{ width: '49%', marginRight: '1%' }}>
          <TextField
            classes={{ root: "textfieldFormAddresses" }}
            placeholder={'Nome'}
            inputProps={{ style: { fontSize: '14px', color: '#000' } }}
            variant="outlined"
            size="small"
            type="text"
            value={editAddress.firstname}
            onChange={(e) => handleEditAddress('firstname', e.target.value)}
            required
            className={styles.fullWidth}
          />
          <InputMask
            mask="99999-999"
            onChange={(e) => handleEditAddress('postcode', e.target.value)}

            value={editAddress.postcode}
            disabled={false}
            maskChar=" ">
            {
              () =>
                <TextField
                  classes={{ root: "textfieldFormAddresses" }}
                  id="postcode"
                  placeholder={'CEP'}
                  inputProps={{ style: { fontSize: '14px', color: '#000' } }}
                  variant="outlined"
                  size="small"
                  type="text"
                  required
                  className={styles.fullWidth}
                />
            }
          </InputMask>
          <TextField
            classes={{ root: "textfieldFormAddresses" }}
            placeholder={'Complemento'}
            inputProps={{ style: { fontSize: '14px', color: '#000' } }}
            variant="outlined"
            size="small"
            value={editAddress.street[3] !== undefined ? editAddress.street[3] : ""}
            onChange={(e) => handleEditAddress('complement', e.target.value)}
            type="text"
            required
            style={{ width: '69%', margin: '5px 0', marginRight: '1%' }}
          />
          <TextField
            classes={{ root: "textfieldFormAddresses" }}
            id="number"
            placeholder={'N°'}
            inputProps={{ style: { fontSize: '14px', color: '#000' } }}
            variant="outlined"
            size="small"
            type="text"
            required
            style={{ width: '29%', margin: '5px 0', marginLeft: '1%' }}
            value={editAddress.street[1]}
            onChange={(e) => handleEditAddress('number', e.target.value)}
          />
          <TextField
            classes={{ root: "textfieldFormAddresses" }}
            id="city"
            placeholder={'Cidade'}
            inputProps={{ style: { fontSize: '14px', color: '#000' } }}
            value={editAddress.city}
            variant="outlined"
            size="small"
            type="text"
            onChange={(e) => handleEditAddress('city', e.target.value)}
            required
            className={styles.fullWidth}
          />
          <InputMask
            mask="(99) 9 9999-9999"
            onChange={(e) => handleEditAddress('telephone', e.target.value)}
            value={editAddress.telephone}
            disabled={false}
            maskChar=" ">
            {
              () =>
                <TextField
                  classes={{ root: "textfieldFormAddresses" }}
                  id="telephone"
                  placeholder={'Telefone'}
                  inputProps={{ style: { fontSize: '14px', color: '#000' } }}
                  variant="outlined"
                  size="small"
                  type="text"
                  required
                  className={styles.fullWidth}
                />
            }
          </InputMask>
        </div>
        <div style={{ width: '50%' }}>
          <TextField
            classes={{ root: "textfieldFormAddresses" }}
            placeholder={'Sobrenome'}
            inputProps={{ style: { fontSize: '14px', color: '#000' } }}
            variant="outlined"
            size="small"
            type="text"
            value={editAddress.lastname}
            onChange={(e) => handleEditAddress('lastname', e.target.value)}
            required
            className={styles.fullWidth}
          />
          <TextField
            classes={{ root: "textfieldFormAddresses" }}
            id="street"
            placeholder={'Rua'}
            inputProps={{ style: { fontSize: '14px', color: '#000' } }}
            variant="outlined"
            size="small"
            value={editAddress.street[0]}
            type="text"
            onChange={(e) => handleEditAddress('street', e.target.value)}
            required
            className={styles.fullWidth}
          />
          <TextField
            classes={{ root: "textfieldFormAddresses" }}
            id="block"
            value={editAddress.street[2]}
            placeholder={'Bairro'}
            inputProps={{ style: { fontSize: '14px', color: '#000' } }}
            variant="outlined"
            size="small"
            type="text"
            onChange={(e) => handleEditAddress('block', e.target.value)}
            required
            className={styles.fullWidth}
          />
          <TextField
            select
            classes={{ root: "textfieldFormAddresses" }}
            size="small"

            value={editAddress.region.region_id.toString()}
            onChange={(e, child: any) => { handleEditAddress('region', child.props) }}
            SelectProps={{
              MenuProps: {
                style: { marginTop: '40px', maxHeight: '60vh' }
              }
            }}
            variant="outlined"
            className={styles.fullWidth}
          >
            {regions.map((region) => {
              return (
                <Menuitem
                  id={region.code}
                  key={region.id}
                  classes={{ selected: styles.selectActive }}
                  className={styles.selectOptions}
                  value={region.id}>{region.name}
                </Menuitem>
              )
            })}
          </TextField>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <Button onClick={() => handleOpenEditAddress(savingIn)} variant='text' color='primary' style={{ textTransform: 'none', width: '15%' }}>Cancelar</Button>
          <Button onClick={() => saveEditAddress()} variant='contained' color='primary' style={{ width: '15%', fontWeight: 'bold', boxShadow: 'none' }}>Salvar</Button>
        </div>
      </div>
    </div>
  )
}

export default editAddress
