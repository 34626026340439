import React from 'react'
import Menu from '../../components/menu'
import { Button } from '@material-ui/core'
import StatusStepper from './statusStepper'
import styles from '../../MyAccount.module.scss'
import customOptions from 'src/components/Products/components/customOptions'

type orderType = {
   order: any
}

const order: React.FC<orderType> = ({ order }) => {
   console.log(order);
   const envio = (order !== undefined && order.status_histories.length > 0) ? JSON.parse(order?.status_histories.find((x:any) => x.status === "pendente").comment) : "";
   

   var date = new Date(order?.created_at.replace(/-/g, "/"));
   var day = date.getDate();
   var monthIdx = date.getMonth();
   var year = date.getFullYear();
   var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
   var month = months[monthIdx];

   return (
      <div style={{ width: '80%', backgroundColor: '#fff' }}>
         <div style={{ borderBottom: '2px solid #f8f7f6', display: 'flex' }}>
            <div style={{ borderRight: '2px solid #f8f7f6', width: '50%', padding: '15px', display: 'flex' }}>
               <div style={{ width: '60%', flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
                  <div>
                     <span>N° do Pedido: <strong>{order?.entity_id}</strong></span>
                     <br></br>
                     {day < 10 ?
                        <span>Realizado em: 0{day}/{month}/{year}</span> :
                        <span>Realizado em: {day}/{month}/{year}</span>
                     } <br />
                  </div>
               </div>
               <div style={{ width: '40%', flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
                  <Button variant='outlined' color='primary' size='small' fullWidth style={{ marginBottom: '7px' }}>
                     Pedir novamente
                  </Button>
                  <Button variant='outlined' color='primary' size='small' fullWidth>
                     Gerar boleto
                  </Button>
               </div>
            </div>
            <div style={{ padding: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%' }}>
               <StatusStepper order={order} />
            </div>
         </div>
         <div >
            <p style={{ padding: '0 15px' }} className={styles.subtitles}>Itens Pedidos</p>
            <div style={{ display: 'flex' }}>
               <div style={{ width: '13%', textAlign: 'center', backgroundColor: '#FBECE8', borderRight: '2px solid #f8f7f6', padding: '10px' }}>SKU</div>
               <div style={{ width: '56%', textAlign: 'center', backgroundColor: '#FBECE8', borderRight: '2px solid #f8f7f6', padding: '10px' }}>PRODUTO</div>
               <div style={{ width: '11%', textAlign: 'center', backgroundColor: '#FBECE8', borderRight: '2px solid #f8f7f6', padding: '10px' }}>PREÇO</div>
               <div style={{ width: '8%', textAlign: 'center', backgroundColor: '#FBECE8', borderRight: '2px solid #f8f7f6', padding: '10px' }}>QTDE</div>
               <div style={{ width: '12%', textAlign: 'center', backgroundColor: '#FBECE8', padding: '10px' }}>SUBTOTAL</div>
            </div>

            {order?.items.map((item: any, index: any) => {
               if (item.product_type !== "configurable") {
                  return (
                     <div key={index} style={{ display: 'flex', borderBottom: '2px solid #f8f7f6' }}>
                        <div style={{ width: '13%', justifyContent: 'center', borderRight: '2px solid #f8f7f6', padding: '10px', alignItems: 'center', display: 'flex', textAlign: 'center' }}>
                           <span style={{ wordBreak: 'break-all' }}>{item.sku}</span>
                        </div>
                        <div style={{ width: '56%', justifyContent: 'center', borderRight: '2px solid #f8f7f6', padding: '10px', alignItems: 'center', display: 'flex', textAlign: 'center', flexWrap: 'wrap' }}>
                           <div style={{ width: '100%' }}>
                              {item.name}
                           </div>
                           <div style={{ backgroundColor: '#fbece8', padding: '3px 10px', borderRadius: '5px', height: 'fit-content', textAlign: 'start' }}>
                              {("parent_item" in item) && item?.parent_item?.product_option?.extension_attributes?.custom_options?.map((customOption: any, index: any) => (
                                 <div key={index} style={{ fontSize: '12px' }}>
                                    {customOption?.title}: <strong>{customOption?.option_value}</strong>
                                 </div>
                              ))}
                              {item?.product_option?.extension_attributes?.custom_options?.map((customOption: any, index: any) => (
                                 <div key={index} style={{ fontSize: '12px' }}>
                                    {customOption.title}: <strong>{customOption?.option_value}</strong>
                                 </div>
                              ))}
                           </div>
                        </div>
                        <div style={{ width: '11%', justifyContent: 'center', borderRight: '2px solid #f8f7f6', padding: '10px', alignItems: 'center', display: 'flex', textAlign: 'center' }}>
                           R$ {item.price.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}
                        </div>
                        <div style={{ width: '8%', justifyContent: 'center', borderRight: '2px solid #f8f7f6', padding: '10px', alignItems: 'center', display: 'flex', textAlign: 'center' }}>
                           {item.qty_ordered}
                        </div>
                        <div style={{ width: '12%', justifyContent: 'center', padding: '10px', alignItems: 'center', display: 'flex', textAlign: 'center' }}>
                           R$ {(item.qty_ordered * item.price).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}
                        </div>
                     </div>)
               }
            })}
            <div style={{ display: 'flex', borderBottom: '2px solid #f8f7f6' }}>
               <div style={{ width: '88%', textAlign: 'end', borderRight: '2px solid #f8f7f6', padding: '10px 20px 10px 10px' }}>Subtotal</div>
               <div style={{ width: '12%', textAlign: 'center', padding: '10px' }}>R$ {order?.subtotal.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</div>
            </div>
            <div style={{ display: 'flex', borderBottom: '2px solid #f8f7f6' }}>
               <div style={{ width: '88%', textAlign: 'end', borderRight: '2px solid #f8f7f6', padding: '10px 20px 10px 10px' }}>Envio e manuseio</div>
               <div style={{ width: '12%', textAlign: 'center', padding: '10px' }}>R$ {envio?.shippingPrice}</div>
            </div>
            <div style={{ display: 'flex', borderBottom: '2px solid #f8f7f6' }}>
               <div style={{ width: '88%', textAlign: 'end', borderRight: '2px solid #f8f7f6', padding: '10px 20px 10px 10px', fontWeight: 'bold' }}>TOTAL</div>
               
               <div style={{ width: '12%', textAlign: 'center', padding: '10px', fontWeight: 'bold' }}>R$ {(order?.subtotal+(+(envio?.shippingPrice?.replace(',','.')))).toFixed(2).replace('.',',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</div>
            </div>
         </div>
         <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ borderBottom: '2px solid #f8f7f6', width: '50%', padding: '15px', borderRight: '2px solid #f8f7f6' }}>
               <span className={styles.subtitles}>Pagamento</span>
               <br />
               {order?.payment.method === "billet" ? <strong>Boleto Bancário</strong> :
                  order?.payment.method === "creditcard" ? <strong>Cartão de Crédito</strong> : <strong>Outro Pagamento</strong>
               }
               <br />
               <span>Status do Pagamento: {
                  order?.status === "pending" ? "Pendente" :
                     order?.status === "canceled" ? "Cancelado" : "Confirmado"}</span>
            </div>
            <div style={{ borderBottom: '2px solid #f8f7f6', width: '50%', padding: '15px' }}>
               <span className={styles.subtitles}>Envio</span> <br />
               {
                  envio !== "" && envio !== null ?
                     <span>Correios - {envio?.shippingService} - {envio?.shippingTime} dias úteis após envio.</span> :
                     <span>Correios</span>
               }
            </div>
            <div style={{ width: '50%', padding: '15px', borderRight: '2px solid #f8f7f6' }}>
               <span className={styles.subtitles}>Endereço de Cobrança</span>
               <br />
               <span>{`${order?.billing_address.firstname} ${order?.billing_address.lastname}`}</span> <br />
               <span>{`${order?.billing_address.street[0]}, ${order?.billing_address.street[1]}, ${order?.billing_address.street[2]}`}</span> <br />
               <span>{`${order?.billing_address.city}, ${order?.billing_address.region}`}</span> <br />
               <span>{`${order?.billing_address.postcode}`}</span> <br />
               <span>{`${order?.billing_address.telephone}`}</span> <br />
            </div>
            <div style={{ width: '50%', padding: '15px' }}>
               <span className={styles.subtitles}>Endereço de Entrega</span>
               <br />
               <span>{`${order?.extension_attributes.shipping_assignments[0].shipping.address.firstname} ${order?.extension_attributes.shipping_assignments[0].shipping.address.lastname}`}</span> <br />
               <span>{`${order?.extension_attributes.shipping_assignments[0].shipping.address.street[0]}, ${order?.extension_attributes.shipping_assignments[0].shipping.address.street[1]}, ${order?.extension_attributes.shipping_assignments[0].shipping.address.street[2]}`}</span> <br />
               <span>{`${order?.extension_attributes.shipping_assignments[0].shipping.address.city}, ${order?.extension_attributes.shipping_assignments[0].shipping.address.region}`}</span> <br />
               <span>{`${order?.extension_attributes.shipping_assignments[0].shipping.address.postcode}`}</span> <br />
               <span>{`${order?.extension_attributes.shipping_assignments[0].shipping.address.telephone}`}</span> <br />
            </div>
         </div>
      </div>
   )
}

export default order
