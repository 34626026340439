import React, { useState } from 'react'
import styles from '../MyAccount.module.scss'
import MenuItem from '@material-ui/core/MenuItem'
import { Link, navigate } from 'gatsby'
import { logout, toggleChangePassword, toggleEditAddress, setSelectedOrder } from '../../../store/actions'

type menuType = {
   active: number
}

const menu: React.FC<menuType> = ({ active }) => {
   const [listMenuOpen, setListMenuOpen] = useState(false)
   let step = '';

   const toggleListMenu = () => {
      setListMenuOpen(!listMenuOpen)
   }

   switch (active) {
      case 1: {
         step = "Principal"
         break;
      }
      case 2: {
         step = "Meus dados"
         break;
      }
      case 3: {
         step = "Meus endereços"
         break;
      }
      case 4: {
         step = "Meus pedidos"
         break;
      }
      case 5: {
         step = "Avaliações"
         break;
      }
      case 6: {
         step = "Lista de desejos"
         break;
      }
      case 7: {
         step = "Newsletter"
         break;
      }
      default: {
         break;
      }
   }

   return (
      <div className={styles.menu}>
         <MenuItem
            style={active === 1 ? { backgroundColor: '#fff', fontWeight: 'bold', color: '#e8927c' } : undefined}
            className={styles.menuItem}
            component={Link}
            to={'/minha-conta'}
         >
            Principal
                     </MenuItem>
         <MenuItem
            style={active === 2 ? { backgroundColor: '#fff', fontWeight: 'bold', color: '#e8927c' } : undefined}
            className={styles.menuItem}
            onClick={() => toggleChangePassword(false)}
            component={Link}
            to={'/minha-conta/dados'}
         >
            Meus dados
                     </MenuItem>
         <MenuItem
            style={active === 3 ? { backgroundColor: '#fff', fontWeight: 'bold', color: '#e8927c' } : undefined}
            className={styles.menuItem}
            onClick={() => toggleEditAddress(false)}
            component={Link}
            to={'/minha-conta/enderecos'}
         >Meus endereços
                  </MenuItem>
         <MenuItem
            style={active === 4 ? { backgroundColor: '#fff', fontWeight: 'bold', color: '#e8927c' } : undefined}
            className={styles.menuItem}
            onClick={() => setSelectedOrder(null)}
            component={Link}
            to={'/minha-conta/pedidos'}
         >
            Meus pedidos
                     </MenuItem>
         <MenuItem
            style={active === 5 ? { backgroundColor: '#fff', fontWeight: 'bold', color: '#e8927c' } : undefined}
            className={styles.menuItem}
            component={Link}
            to={'/minha-conta/avaliacoes'}
         >
            Avaliações
                     </MenuItem>
         <MenuItem
            style={active === 6 ? { backgroundColor: '#fff', fontWeight: 'bold', color: '#e8927c' } : undefined}
            className={styles.menuItem}
            component={Link}
            to={'/minha-conta/lista-de-desejos'}
         >
            Lista de desejos
                     </MenuItem>
         <MenuItem
            style={active === 7 ? { backgroundColor: '#fff', fontWeight: 'bold', color: '#e8927c' } : undefined}
            className={styles.menuItem}
            component={Link}
            to={'/minha-conta/newsletter'}
         >
            Newsletter
                     </MenuItem>
         <MenuItem
            className={styles.menuItem}
            onClick={() => { navigate('/'); logout(); }}
            style={{ color: '#b2b2b2' }}
         >
            Sair
            </MenuItem>
      </div>
   )
}

export default menu
