import React from 'react'
import styles from '../MyAccount.module.scss'
import Menu from '../components/menu'

type RatingsType = {

}

const Ratings: React.FC<RatingsType> = () => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', minHeight: '60vh' }}>
      <Menu active={5} />
      <div style={{ width: '80%', backgroundColor: '#fff' }}>
        <p className={styles.primaryColor}>Você ainda não enviou nenhuma avaliação.</p>
      </div>
    </div>
  )
}

export default Ratings
