import React from 'react'
import Menu from '../components/menu'
import styles from '../MyAccount.module.scss'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'
import { subscribeToNewsletter, unsubscribeToNewsletter } from '../../../store/actions'

type NewsletterType = {

}

const Newsletter: React.FC<NewsletterType> = () => {
  const userData = useSelector((state: any) => state.userData);
  const userAuth = useSelector((state: any) => state.userAuth);


  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', minHeight: '60vh' }}>
      <Menu active={7} />
      <div className={styles.gridNewsletter}>
        <p className={styles.pNewsletter}>Quer receber as melhores ofertas e conteúdo sobre joias na sua caixa de entrada?</p>
        <span>Ao se cadastrar na nossa newsletter, você terá acesso antecipado a promoções especiais, dicas e mais!</span> <br />
        {
          userData.extension_attributes?.is_subscribed ?
            <Button onClick={() => unsubscribeToNewsletter(userData, userAuth)} variant='outlined' color='primary' size='small' className={styles.unsubscribe}>Descadastrar</Button> :
            <Button onClick={() => subscribeToNewsletter(userData, userAuth)} variant='contained' color='primary' size='small' className={styles.subscribe}>Quero me cadastrar</Button>
        }
      </div>
    </div>
  )
}

export default Newsletter
