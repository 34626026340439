import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepConnector from '@material-ui/core/StepConnector'
import styles from '../../MyAccount.module.scss'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'

type statusStepperType = {
   order: any
}

const statusStepper: React.FC<statusStepperType> = ({ order }) => {
   /*
   <div style={{ textTransform: 'uppercase', fontWeight: 'bold', color: '#cb242c', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '55px'  }}>
      <CancelRoundedIcon fontSize='small' /> 
      &nbsp;
      Cancelado
   </div>
   */


   return (
      <>
         {
            order?.status === 'canceled' || order?.status === "retorno_solictado" ?
               <div>
                  {
                     (order?.status === 'canceled') &&
                     <div style={{ textTransform: 'uppercase', fontWeight: 'bold', color: '#cb242c', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '55px' }}>
                        <CancelRoundedIcon fontSize='small' />
                        &nbsp;
                        Cancelado
                     </div>
                  }
                  {
                     (order?.status === "retorno_solictado") &&
                     <div style={{ textTransform: 'uppercase', fontWeight: 'bold', color: 'lightorange', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '55px' }}>
                        Retorno Solicitado
                     </div>
                  }
               </div> :
               <Stepper color='primary' activeStep={
                  order?.status === 'pending' ? 0 :
                     order?.status === 'pagamento_confirmado' ? 1 :
                        order?.status === 'em_separacao' ? 2 :
                           order?.status === 'complete' ? 3 : 0
               } connector={<StepConnector />} alternativeLabel classes={{ root: styles.stepper }}>
                  <Step color='primary'>
                     <StepLabel StepIconProps={{ classes: { completed: styles.completedIcon } }} classes={{ alternativeLabel: styles.rootLabel, active: styles.active, completed: styles.completed, label: styles.label, iconContainer: styles.iconContainer }}>
                        Pagamento pendente
                        </StepLabel>
                  </Step>
                  <Step color='primary'>
                     <StepLabel StepIconProps={{ classes: { completed: styles.completedIcon } }} classes={{ alternativeLabel: styles.rootLabel, active: styles.active, completed: styles.completed, label: styles.label, iconContainer: styles.iconContainer }}>
                        Pagamento confirmado
                     </StepLabel>
                  </Step>
                  <Step color='primary'>
                     <StepLabel StepIconProps={{ classes: { completed: styles.completedIcon } }} classes={{ alternativeLabel: styles.rootLabel, active: styles.active, completed: styles.completed, label: styles.label, iconContainer: styles.iconContainer }}>
                        Em separação
                     </StepLabel>
                  </Step>

                  <Step color='primary'>
                     <StepLabel StepIconProps={{ classes: { completed: styles.completedIcon } }} classes={{ alternativeLabel: styles.rootLabel, active: styles.active, completed: styles.completed, label: styles.label, iconContainer: styles.iconContainer }}>
                        Pedido enviado
                     </StepLabel>
                  </Step>
               </Stepper>
         }
      </>
   )
}

export default statusStepper
