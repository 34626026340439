import React from 'react'

type NotFoundComponentType = {
  path: any
}

const NotFoundComponent: React.FC<NotFoundComponentType> = () => {
  return (
    <div>
      404
    </div>
  )
}

export default NotFoundComponent
