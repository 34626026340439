import React from 'react'
import styles from '../../MyAccount.module.scss'
import Button from '@material-ui/core/Button'
import Textfield from '@material-ui/core/TextField'
import { handleChangePassword, toggleChangePassword } from '../../../../store/actions'

type newPasswordType = {
   userAuth: any
   passwordState: {
      currentPassword: string;
      newPassword: string;
      confirmPassword: string;
   }
   handleChangePasswordState: (label: any, value: any) => void
}

const newPassword: React.FC<newPasswordType> = ({ passwordState, handleChangePasswordState, userAuth }) => {
   return (
      <div className={styles.gridData}>
         <div style={{ width: '300px' }}>
            <Textfield
               className="textfieldFormAddresses"
               placeholder="Senha atual"
               variant="outlined"
               size="small"
               type="password"
               value={passwordState.currentPassword}
               onChange={(e: any) => handleChangePasswordState('currentPassword', e.target.value)}
               fullWidth
               style={{ margin: '5px 0' }}
            />
            <Textfield
               className="textfieldFormAddresses"
               placeholder="Nova senha"
               variant="outlined"
               size="small"
               type="password"
               value={passwordState.newPassword}
               fullWidth
               onChange={(e: any) => handleChangePasswordState('newPassword', e.target.value)}
               style={{ margin: '5px 0' }}
            />
            <Textfield
               className="textfieldFormAddresses"
               placeholder="Confirmar nova senha"
               variant="outlined"
               size="small"
               type="password"
               value={passwordState.confirmPassword}
               fullWidth
               onChange={(e: any) => handleChangePasswordState('confirmPassword', e.target.value)}
               style={{ margin: '5px 0' }}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', margin: '10px 0' }}>
               <Button variant="text" color="primary" style={{ textTransform: 'none' }} onClick={() => toggleChangePassword(false)}>
                  Cancelar
            </Button>
               <Button variant="contained" color="primary" style={{ boxShadow: 'none', width: '40%', fontWeight: 'bold' }} onClick={() => { handleChangePassword(userAuth, passwordState.currentPassword, passwordState.newPassword, passwordState.confirmPassword) }}>
                  Salvar
            </Button>
            </div>
         </div>
      </div>
   )
}

export default newPassword
