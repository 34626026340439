import React from 'react'
import StarRatingComponent from 'react-star-rating-component'
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded'
import StarHalfRoundedIcon from '@material-ui/icons/StarHalfRounded'
import StarRoundedIcon from '@material-ui/icons/StarRounded'

type RatingStarsType = {
   
}

const RatingStars: React.FC<RatingStarsType> = () => {
   return (
      <StarRatingComponent
         name="ratingstars"
         editing={false}
         starColor="#e8927c"
         emptyStarColor="#e8927c"
         starCount={5}
         value={2.5}
         renderStarIcon={(index, value) => {
            return (
               <span>
                  {index <= value ? <StarRoundedIcon fontSize="small" style={{maxHeight: '17px'}} /> : <StarBorderRoundedIcon fontSize="small" style={{maxHeight: '17px'}} />}
               </span>
            );
         }}
         renderStarIconHalf={() => {
            return (
               <span>
                  <span><StarHalfRoundedIcon color="primary" fontSize="small" style={{maxHeight: '17px'}}/></span>
               </span>
            );
         }}
      />
   )
}

export default RatingStars
