import React from 'react'
import styles from '../../MyAccount.module.scss'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'
import { Link } from 'gatsby'

type newsletterType = {

}

const newsletter: React.FC<newsletterType> = () => {
   const userData = useSelector((state: any) => state.userData)
   return (
      <div className={styles.divNewsletter}>
         <span className={styles.subtitles}>Newsletter</span> &nbsp;&nbsp;&nbsp;
         <Button
            disableTouchRipple
            component={Link}
            to={'/minha-conta/newsletter'}
            variant='text'
            color='primary'
            size='small'
            className={styles.padding0}
            classes={{ label: styles.labelButton }}
         >
            Inscrever-se
         </Button>
         <div className={styles.paddingTop5}>
            {userData.extension_attributes.is_subscribed === false ?
               <span> Você não está inscrito em nenhum boletim atualmente. <br />
               Quer receber as melhores ofertas e conteúdo sobre joias na sua caixa de entrada? <strong>INSCREVA-SE!</strong> </span> :
               <span> Está inscrito! Você receberá as melhores ofertas e conteúdo sobre joias na sua caixa de entrada!</span>
            }
         </div>
      </div>
   )
}

export default newsletter