import React, { useState } from 'react'
import styles from '../MyAccount.module.scss'
import { useSelector } from 'react-redux'
import EditAddress from './components/editAddress'
import { handleSaveUserData } from '../../../store/actions'
import Menu from '../components/menu'
import ClassNames from 'classnames/bind'
import Button from '@material-ui/core/Button'

let cx = ClassNames.bind(styles)

type AddressesType = {

}

const Addresses: React.FC<AddressesType> = () => {
  const userAuth = useSelector((state: any) => state.userAuth);
  const userData = useSelector((state: any) => state.userData);
  const [editAddress, setEditAddress] = useState({});
  const [savingIn, setSavingIn] = useState("");
  const [openEditAddress, setOpenEditAddress] = useState(false);
  const handleEditAddress = (label: any, value: any) => {
    const copyAddress = { ...editAddress };

    switch (label) {

      case 'street': {
        const copyStreet = [...copyAddress.street]
        copyStreet[0] = value
        copyAddress.street = copyStreet
      }
        break;
      case 'number': {
        const copyStreet = [...copyAddress.street]
        copyStreet[1] = value
        copyAddress.street = copyStreet
      }
        break;
      case 'block': {
        const copyStreet = [...copyAddress.street]
        copyStreet[2] = value
        copyAddress.street = copyStreet
      }
        break;
      case 'complement': {
        const copyStreet = [...copyAddress.street]
        copyStreet[3] = value
        copyAddress.street = copyStreet
      }
        break;
      case 'region':
        copyAddress.region = {
          region_code: value.id,
          region: value.children,
          region_id: parseInt(value.value)
        }
        copyAddress.region_id = parseInt(value.value)
        break;
      default:
        copyAddress[label] = value
        break;
    }
    setEditAddress(copyAddress)
  }
  const handleOpenEditAddress = (savingReference: any) => {
    if (savingReference !== savingIn) {
      setOpenEditAddress(true);
      setSavingIn(savingReference);
      if (savingReference === "billing") {
        setEditAddress(JSON.parse(JSON.stringify(userData.addresses.find(x => x.default_billing === true))))
      } else {
        setEditAddress(JSON.parse(JSON.stringify(userData.addresses.find(x => x.default_shipping === true))))
      }
    } else {
      setOpenEditAddress(false);
      setSavingIn("");
      setEditAddress({})
    }
  }
  const saveEditAddress = () => {
    const copyUserData = JSON.parse(JSON.stringify(userData))
    if (savingIn === "billing") {
      const billingIdx = copyUserData.addresses.findIndex(x => x.default_billing === true)
      copyUserData.addresses[billingIdx] = editAddress;
    } else if (savingIn === "shipping") {
      const shippingIdx = copyUserData.addresses.findIndex(x => x.default_shipping === true)
      copyUserData.addresses[shippingIdx] = editAddress;
    }
    handleSaveUserData(userAuth, copyUserData);
    handleOpenEditAddress(savingIn);
  }

  const ClassName = cx({
    displayNone: openEditAddress,
    displayBlock: !openEditAddress,
    gridData: !openEditAddress
  });

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', minHeight: '60vh' }}>
      <Menu active={3} />
      <div className={ClassName}>
        {userData.addresses.map((address: any, index: any) => {
          if (address.default_billing === true) {
            return (
              <div key={index}>
                <span className={styles.subtitles}>Endereço de cobrança</span>
                <Button
                  disableTouchRipple
                  onClick={() => { handleOpenEditAddress('billing') }}
                  variant='text'
                  color='primary'
                  size='small'
                  className={styles.padding0}
                  classes={{ label: styles.labelButton }}
                >
                  Editar
                  </Button>
                <br />
                <span>{`${address?.street[3]}`}</span> <br />
                <span>{`${address.firstname} ${address.lastname}`}</span> <br />
                <span>{`${address.street[0]}, ${address.street[1]}, ${address.street[2]}`}</span> <br />
                <span>{`${address.city}, ${address.region.region}`}</span> <br />
                <span>{`${address.postcode}`}</span> <br />
                <span>{`${address.telephone}`}</span> <br />
              </div>
            )
          } else if (address.default_shipping === true) {
            return (
              <div key={index} style={{ paddingTop: '30px' }}>
                <span className={styles.subtitles}>Endereço de envio</span>
                <Button
                  disableTouchRipple
                  onClick={() => { handleOpenEditAddress('shipping') }}
                  variant='text'
                  color='primary'
                  size='small'
                  className={styles.padding0}
                  classes={{ label: styles.labelButton }}
                >
                  Editar
                </Button>
                <br />
                <span>{`${address?.street[3]}`}</span> <br />
                <span>{`${address.firstname} ${address.lastname}`}</span> <br />
                <span>{`${address.street[0]}, ${address.street[1]}, ${address.street[2]}`}</span> <br />
                <span>{`${address.city}, ${address.region.region}`}</span> <br />
                <span>{`${address.postcode}`}</span> <br />
                <span>{`${address.telephone}`}</span> <br />
              </div>
            )
          }
        })}

      </div>
      {
        openEditAddress &&
        <EditAddress
          savingIn={savingIn}
          editAddress={editAddress}
          handleOpenEditAddress={handleOpenEditAddress}
          handleEditAddress={handleEditAddress}
          saveEditAddress={saveEditAddress}
        />
      }
    </div>
  )
}

export default Addresses
