import React, { useState, useEffect } from 'react'
import styles from '../MyAccount.module.scss'
import { useSelector } from 'react-redux'
import { handleChangePassword, handleSaveUserData, toggleChangePassword } from '../../../store/actions'
import Menu from '../components/menu'
import ClassNames from 'classnames/bind'
import Textfield from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import NewPassword from './components/newPassword'

let cx = ClassNames.bind(styles);

type MyDataType = {

}

const MyData: React.FC<MyDataType> = () => {
  const userAuth = useSelector((state: any) => state.userAuth);
  const userData = useSelector((state: any) => state.userData);
  const openChangePassword = useSelector((state: any) => state.myAccount.openChangePassword);

  const [passwordState, setPasswordState] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  })
  const [editUserData, setEditUserData] = useState(JSON.parse(JSON.stringify(userData)));


  const handleChangeEditUserData = (label: any, value: any) => {

    const copyEditUserData = { ...editUserData };
    if (label === 'telephone') {
      copyEditUserData.addresses[0].telephone = value;
    } else if (label === 'cpf') {
      copyEditUserData.custom_attributes[cpfIndex].value = value;
    } else if (label === 'cnpj') {
      copyEditUserData.custom_attributes[cnpjIndex].value = value;
    } else {
      copyEditUserData[label] = value;
    }
    setEditUserData(copyEditUserData);
  }

  const handleChangePasswordState = (label: any, value: any) => {
    const copyPasswordState: any = { ...passwordState };
    copyPasswordState[label] = value;
    setPasswordState(copyPasswordState);
  }
  console.log(editUserData)
  const cpfIndex = editUserData.custom_attributes.findIndex((x: any) => x.attribute_code === "cpf")
  const cnpjIndex = editUserData.custom_attributes.findIndex((x: any) => x.attribute_code === "cnpj")

  const ClassName = cx({
    displayNone: openChangePassword,
    displayBlock: !openChangePassword,
    gridData: !openChangePassword
  });

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', minHeight: '60vh' }}>
      <Menu active={2} />
      <div className={ClassName} style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
        <span>Para atualizar seus dados, edite os campos abaixo e clique em "<b>Salvar</b>".</span>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: '20px' }}>
          {(cpfIndex !== -1) ?
            <Textfield
              className="textfieldFormAddresses"
              variant="outlined"
              placeholder="CPF"
              value={editUserData.custom_attributes[cpfIndex].value}
              onChange={(e) => handleChangeEditUserData('cpf', e.target.value)}
              size="small"
              style={{ width: '32.66%', margin: '5px 0' }}
            /> :
            <Textfield
              className="textfieldFormAddresses"
              variant="outlined"
              placeholder="CNPJ"
              value={editUserData.custom_attributes[cnpjIndex].value}
              onChange={(e) => handleChangeEditUserData('cnpj', e.target.value)}
              size="small"
              style={{ width: '32.66%', margin: '5px 0' }}
            />
          }
          <Textfield
            className="textfieldFormAddresses"
            variant="outlined"
            placeholder="CPF"
            value={editUserData.firstname}
            onChange={(e) => handleChangeEditUserData('firstname', e.target.value)}
            size="small"
            style={{ width: '32.66%', margin: '5px 0' }}
          />
          <Textfield
            className="textfieldFormAddresses"
            variant="outlined"
            placeholder="CPF"
            value={editUserData.lastname}
            onChange={(e) => handleChangeEditUserData('lastname', e.target.value)}
            size="small"
            style={{ width: '32.66%', margin: '5px 0' }}
          />
          <Textfield
            select
            className="textfieldFormAddresses"
            variant="outlined"
            value={editUserData.gender}
            onChange={(e) => handleChangeEditUserData('gender', parseInt(e.target.value))}
            size="small"
            style={{ width: '32.66%', margin: '5px 0' }}
          >
            <MenuItem
              classes={{ selected: styles.selectedItem }}
              className={styles.MenuItem}
              value={1}
            >
              Masculino
            </MenuItem>
            <MenuItem
              classes={{ selected: styles.selectedItem }}
              className={styles.MenuItem}
              value={2}
            >
              Feminino
            </MenuItem>
          </Textfield>
          <Textfield
            className="textfieldFormAddresses"
            variant="outlined"
            placeholder="CPF"
            value={editUserData.addresses[0].telephone}
            onChange={(e) => handleChangeEditUserData('telephone', e.target.value)}
            size="small"
            style={{ width: '32.66%', margin: '5px 0' }}
          />
          <Textfield
            className="textfieldFormAddresses"
            variant="outlined"
            placeholder="CPF"
            value={editUserData.dob}
            onChange={(e) => handleChangeEditUserData('dob', e.target.value)}
            size="small"
            type="date"
            style={{ width: '32.66%', margin: '5px 0' }}
          />

        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', paddingTop:'20px' }}>
          <Button variant="outlined" color="primary" onClick={() => toggleChangePassword(true)}>
            Alterar senha
        </Button>
          <Button variant="contained" color="primary" onClick={() => { handleSaveUserData(userAuth, editUserData) }} style={{marginLeft: '20px', boxShadow: 'none', width: '13%', fontWeight: 'bold' }}>
            Salvar
        </Button>
        </div>
      </div>
      {
        openChangePassword &&
        <NewPassword userAuth={userAuth} handleChangePasswordState={handleChangePasswordState} passwordState={passwordState} />
      }


    </div>
  )
}

export default MyData
