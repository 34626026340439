import React, { useState, useEffect } from 'react'
import { toggleChangePassword, toggleEditAddress, setLoadingTrue, getCustomerLastOrder, setLoadingFalse } from '../../../store/actions'
import {
  Greeting,
  Addresses,
  Information,
  Newsletter,
  Order,
  Ratings
} from './components'
import Menu from '../components/menu'
import { useSelector } from 'react-redux'

type MainType = {

}

const Main: React.FC<MainType> = () => {
  const userAuth = useSelector((state: any) => state.userAuth)
  const [order, setOrder] = useState(undefined);
  console.log(order)
  useEffect(() => {

    async function getData() {
      await setLoadingTrue()
      getCustomerLastOrder(userAuth).then(response => {
        console.log(response !== null)
        if (response !== null) {
          setOrder(response)
        }
        setLoadingFalse()
      })
    }
    getData();

  }, [])
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <Menu active={1} />
      <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '80%', backgroundColor: '#fff' }}>
        <div style={{ width: '50%', borderRight: '2px solid #f8f7f6', borderBottom: '2px solid #f8f7f6' }}>
          <div style={{ borderBottom: '2px solid #f8f7f6' }}>
            <Greeting />
          </div>
          <div>
            <Information toggleChangePassword={toggleChangePassword} />
          </div>
        </div>
        <div style={{ width: '50%', borderBottom: '2px solid #f8f7f6' }}>
          <Order order={order} />
        </div>

        <div style={{ width: '100%', borderBottom: '2px solid #f8f7f6' }}>
          <Addresses toggleEditAddress={toggleEditAddress} />
        </div>
        <div style={{ width: '50%', borderRight: '2px solid #f8f7f6' }}>
          <Ratings />
        </div>
        <div style={{ width: '50%', borderRight: '2px solid #f8f7f6' }}>
          <Newsletter />
        </div>
      </div>
    </div>
  )
}

export default Main
